/**
* Template Name: Butterfly - v4.3.0
* Template URL: https://bootstrapmade.com/butterfly-free-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Red+Hat+Text:400,500,700&display=swap");
body {
  font-family: "Red Hat Text", sans-serif;
  color: #444444;
  background-color: #fff !important;
}

a {
  text-decoration: none;
  color: #49b5e7;
}

a:hover {
  color: #76c7ed;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Red Hat Text", sans-serif;
  color: #101326;
}

.filter-icon {
  filter: invert(54%) sepia(51%) saturate(7132%) hue-rotate(153deg)
    brightness(101%) contrast(80%);
}
.revolut-name {
  cursor: pointer;
  color: #101326;
  font-style: normal;
  font-weight: 600;
  margin-top: 25px;
  background: none;
}

.revolut-name:active {
  background: #30c1be;
}

.revolut-name:focus {
  background: #30c1be;
}
.staff-name {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  width: 120px;
}

.staff-name h4 {
  font-size: 20px;
  color: #101326;
  font-weight: 600;
  background: #30c1be;
}
.copied-icon {
  display: none;
}

.extra-info {
  font-size: 12px;
}

#footer {
  font-family: "Red Hat Text", sans-serif;
  font-size: 12px;
  text-align: center;
  vertical-align: bottom;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0px 15px 0px;
  overflow: hidden;
}

.section-bg {
  background-color: #f4fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2,
h3 {
  font-family: "Red Hat Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #101326;
}

.section-title p {
  margin-bottom: 0;
}

section .img-background {
  position: absolute;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 10px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #49b5e7;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
}

/* hackks : */
.portfolio .portfolio-wrap img.img-fluid {
  height: 100%;
  width: 100%;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #30c1be;
  border-left: 3px solid #30c1be;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #30c1be;
  border-right: 3px solid #30c1be;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #101326;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #101326;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links img {
  filter: invert(54%) sepia(51%) saturate(7132%) hue-rotate(153deg)
    brightness(101%) contrast(80%);
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #49b5e7;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #8dd0f0;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

.portfolio-links img {
  padding-top: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #49b5e7;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #49b5e7;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(15, 57, 76, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
